<template>
  <div :class="inverse ? `card text-white bg-${color}` : 'card'">    
    <div class="card-body">
      <div class="pull-right pt-10 pr-2">
      <div class="card-header-actions">
        <CLink
          href="javascript:void(0)"
          @click="mtd_edit_state"
          class="card-header-action btn-setting text-white"
        >
          <i class="fas fa-recycle"></i>
        </CLink>
        <CLink
          href="javascript:void(0)"
          @click="mtd_edit_lot"
          class="card-header-action btn-setting text-white"
        >
          <i class="fas fa-edit"></i>
        </CLink>
        <CLink
          href="javascript:void(0)"
          class="card-header-action btn-close text-white"
          @click="mtd_delete_lot"
        >
          <i class="fas fa-trash"></i>
        </CLink>
      </div>
    </div>
      <div v-if="header" class="h4 m-0">{{header}}</div>
      <div v-if="text">{{text}}</div>
      <slot v-if="progress">
        <CProgress
          :color="!inverse ? color : ''"
          :value="value"
          :class="{ 'progress-white' : inverse }"
          class="progress-xs my-3 mb-0"
        />
      </slot>
      <small v-if="footer" class="text-muted">
        {{footer}}
      </small>
    </div>  
  </div>
</template>

<script>
import CProgress from '../progress/CProgress'
export default {
  name: 'CWidgetProgress',
  components: {
    CProgress
  },
  props: {
    header: String,
    text: String,
    footer: String,
    color: String,
    inverse: Boolean,
    value: {
      type: Number,
      default: 25
    },
    progress:Boolean,
    data:[]
  },
  methods: {
    mtd_delete_lot: function(){
      let item = this.data;
      this.$emit('mtd_delete_lot',item);
    },
    mtd_edit_state: function(){
      let item = this.data;
      this.$emit('mtd_edit_state',item);
    },
    mtd_edit_lot: function(){
      let item = this.data;
      this.$emit('mtd_edit_lot',item);
    }
  },
}
</script>
