<template>
  <div>
    <CRow>
      <CCol sm="12" md="4">
        <CCard>
          <CCardHeader>
            Proyectos
            <button
              type="button"
              class="btn-sm btn-primary float-right"
              @click="mtd_open_modal(true, 'store')"
            >
              <i class="fas fa-plus"></i>
            </button>
          </CCardHeader>
          <CCardBody
            class="scroll-card scrollbar-primary bordered-primary thin"
          >
            <CCol v-for="(item, index) in data" :key="index" sm="12" md="12">
              <transition name="fade">
                <CCard v-if="true" :color="item.color">
                  <CCardHeader>
                    <label @click="show_street(item.id)" style="color: white;cursor:pointer"
                      >{{ index + 1 }}. {{ item.name }}</label
                    >
                    <div class="card-header-actions">
                      <CLink
                        class="card-header-action btn-minimize"
                        @click="item.collapse = !item.collapse"
                      >
                        <CIcon
                          :name="`cil-chevron-${
                            item.collapse ? 'bottom' : 'top'
                          }`"
                        />
                      </CLink>
                      <CLink
                        href="javascript:void(0)"
                        @click="mtd_view_map(item.maps)"
                        class="card-header-action btn-setting"
                      >
                        <i class="fas fa-eye"></i>
                      </CLink>
                      <CLink
                        href="javascript:void(0)"
                        @click="mtd_show(item.id)"
                        class="card-header-action btn-setting"
                      >
                        <i class="fas fa-edit"></i>
                      </CLink>
                      <CLink
                        href="#"
                        class="card-header-action btn-close"
                        v-on:click="mtd_delete(item)"
                      >
                        <i class="fas fa-trash"></i>
                      </CLink>
                    </div>
                  </CCardHeader>
                  <CCollapse :show="item.collapse" :duration="400">
                    <CCardBody>
                      <label style="color: white">
                        {{ item.description }}
                      </label>
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </transition>
            </CCol>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="12" md="8">
        <CCard>
          <CCardHeader>
            Etapas/Manzanas del proyecto <b>{{ streets.project_name }}</b>
            <button
              v-if="streets.project_id != ''"
              type="button"
              class="btn-sm btn-primary float-right"
              @click="mtd_open_modal_srteet(true, 'store')"
            >
              <i class="fas fa-plus"></i>
            </button>
          </CCardHeader>
          <CCardBody
            class="scroll-card scrollbar-primary bordered-primary thin"
          >
            <div class="row">
              <CCol
                v-for="(item, index) in streets.data"
                :key="index"
                sm="12"
                md="6"
              >
                <transition name="fade">
                  <CCard v-if="true" color="secondary">
                    <CCardHeader>
                      <label @click="show_lots(item.id)" style="color: white;cursor:pointer"
                        >{{ index + 1 }}. {{ item.name }}</label
                      >
                      <div class="card-header-actions">
                        <CLink
                          href="#"
                          class="card-header-action btn-close"
                          v-on:click="mtd_delete_street(item)"
                        >
                          <i class="fas fa-trash"></i>
                        </CLink>
                      </div>
                    </CCardHeader>
                  </CCard>
                </transition>
              </CCol>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="12" md="12">
        <CCard>
          <CCardHeader>
            Lotes de la Etapa/ManzanaS <b>{{ lots.street_name }}</b> del proyecto
            <b>{{ streets.project_name }}</b>
            <button
              v-if="lots.project_streetblock_id != ''"
              type="button"
              class="btn-sm btn-primary float-right"
              @click="mtd_open_modal_lot(true, 'store')"
            >
              <i class="fas fa-plus"></i>
            </button>
          </CCardHeader>
          <CCardBody
            class="scroll-card scrollbar-primary bordered-primary thin"
          >
            <div class="row">
              <CCol
                 v-for="(item, index) in lots.data"
                :key="index"
                sm="12"
                md="3"
              >
              <cWidget
                :header="'Lote: '+item.name"
                :data="item"
                text=""
                footer=""
                :color="color_lot[item.state]"
                inverse :value="25"
                :progress="false"
                @mtd_delete_lot="mtd_delete_lot(item)"
                @mtd_edit_state="mtd_edit_state(item)"
                @mtd_edit_lot="mtd_edit_lot(item)"
              />
              </CCol>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="1" md="1">
          <span style="height: 30px; width: 100%;font-size: 20px;" class="badge float-left badge-success">LIBRE</span>
      </CCol>
      <CCol sm="2" md="2">
          <span style="height: 30px; width: 100%;font-size: 20px;" class="badge float-left badge-warning">COTIZADO</span>
      </CCol>
      <CCol sm="3" md="3">
          <span style="height: 30px; width: 100%;font-size: 20px;" class="badge float-left badge-danger">SEPARADO/VENDIDO</span>
      </CCol>
      <br>
    </CRow>

    <!-- modal -->
    <CModalForm
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            label="Nombre"
            placeholder="Digite nombre"
            v-model="project.name"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Mapa"
            placeholder="Digite Mapa"
            v-model="project.url_maps"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Descripción"
            placeholder="Digite Descripción"
            v-model="project.description"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal add street -->
    <CModalForm
      :size="'md'"
      :title="modal_add_street.title"
      :button="cp_button_add_street"
      :show.sync="modal_add_street.modal_form"
      @mtd_action="modal_action_add_street"
    >
      <CRow>
        <CCol sm="12">
          <cSelectForm
            label="Seleccione una etapa/manzana"
            :options="data_street"
            placeholder="Escoja una etapa/manzana"
            :value.sync="form_add_stret.street_blocks_id"
          />
        </CCol>
      </CRow>
    </CModalForm>
    <!-- modal add lots -->
    <CModalForm
      :size="'md'"
      :title="modal_add_lot.title"
      :button="cp_button_add_lot"
      :show.sync="modal_add_lot.modal_form"
      @mtd_action="modal_action_add_lot"
    >
      <CRow>
        <CCol sm="12" v-if="modal_add_lot.action == 'store'">
          <cSelectForm
            label="Seleccione un lote"
            :options="data_lot"
            placeholder="Escoja un lote"
            :value.sync="form_add_lot.lot_id"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Metraje"
            placeholder="Metraje de lote"
            v-model="form_add_lot.footage"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Precio - 12 meses"
            placeholder="Precio de lote"
            v-model="form_add_lot.amount"
          />
        </CCol>
        <CCol sm="6">
          <CInput
            label="24 meses (%)"
            placeholder="Porcentaje adicional para 24 meses"
            v-model="form_add_lot.percentage_24"
          />
        </CCol>
        <CCol sm="6">
          <CInput
            label="36 meses (%)"
            placeholder="Porcentaje adicional para 36 meses"
            v-model="form_add_lot.percentage_36"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- edit state lot  -->
    <CModalForm
      :size="'md'"
      :title="modal_edit_state_lot.title"
      :button="cp_button_edit_state"
      :show.sync="modal_edit_state_lot.modal_form"
      @mtd_action="modal_action_edit_state_lot"
    >
      <CRow>
        <CCol sm="12">
          <select class="form-control" v-model="form_edit_state.state">
            <option :value="null" disabled>[Seleccione un estado]</option>
            <option value="1">Libre</option>
            <option value="2">Cotizado</option>
            <option value="3">Separado/vendido</option>
          </select>
        </CCol>
      </CRow>
    </CModalForm>
    <!-- modal delete proyecto -->
    <cModalDelete
      title="Borrar Proyecto"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>

    <!-- modal delete etapa-->
    <cModalDelete
      title="Borrar Etapa/manzana de proyecto"
      :boo_modal="modal_delete_street.boo"
      :item="modal_delete_street.item"
      @close_delete="close_delete_street"
      @mtd_commit="mtd_commit_street"
    ></cModalDelete>

    <!-- modal delete lotes-->
    <cModalDelete
      title="Borrar Lote de etapa/manzana"
      :boo_modal="modal_delete_lot.boo"
      :item="modal_delete_lot.item"
      @close_delete="close_delete_lot"
      @mtd_commit="mtd_commit_lot"
    ></cModalDelete>

    <!-- modal maps -->
    <CModalForm
      :size="'lg'"
      :title="'Ubicación del proyecto'"
      :button="false"
      :show.sync="maps.boo"
      @mtd_action="modal_action_close_maps"
    >
      <CRow>
        <CCol sm="12">
          <iframe
            :src="maps.url"
            width="100%"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </CCol>
      </CRow>
    </CModalForm>
  </div>
</template>

<script>
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import cWidget from '../../components/shared/widget/CWidgetProgress.vue';
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  components: {
    CTableWrapper,
    CModalForm,
    cModalDelete,
    cSelectForm,
    cWidget
  },
  data() {
    return {
      prefix: "project",
      fields: [
        "Id",
        "Nombre",
        "Mapa",
        "Descripcion", // resource
      ],
      data: [],
      data_street: [],
      data_lot: [],
      color_lot:['','gradient-success','gradient-warning','gradient-danger'],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      project: {
        id: "",
        name: "",
        url_maps: "",
        description: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
      /**  streets*/
      streets: {
        project_id: "",
        project_name: "",
        data: [],
        lots: [],
      },
      modal_add_street: {
        action: "",
        title: "",
        modal_form: false,
      },
      form_add_stret: {
        project_id: "",
        street_blocks_id: "",
        state: 1,
      },
      modal_delete_street: {
        boo: false,
        item: [],
      },
      /** lots */
      lots: {
        project_streetblock_id: "",
        street_name: "",
        data: [],
      },
      modal_add_lot: {
        action: "",
        title: "",
        modal_form: false,
      },
      form_add_lot: {
        project_streetblock_id: "",
        lot_id: "",
        state: 1,
        footage: "",
        amount: "",
        percentage_24: "",
        percentage_36: "",
      },
      modal_delete_lot: {
        boo: false,
        item: [],
      },
      /** edit state lot */
      modal_edit_state_lot: {
        action: "",
        title: "",
        modal_form: false,
      },
      form_edit_state:{
        id:null,
        state:null,
      },
      /** maps */
      maps:{
        boo:false,
        url:""
      }
    };
  },
  computed: {
    cp_button: function () {
      if (this.project.name != "") return false;
      return true;
    },
    cp_button_add_street: function () {
      if (this.form_add_stret.street_blocks_id != "") return false;
      return true;
    },
    cp_button_add_lot: function () {
      if (
        this.form_add_lot.lot_id != "" &&
        this.form_add_lot.footage != "" &&
        this.form_add_lot.amount != "" &&
        this.form_add_lot.percentage_24 != "" &&
        this.form_add_lot.percentage_36 != ""
      )
        return false;
      return true;
    },
    cp_button_edit_state: function(){
      if (this.form_edit_state.state != null) return false;
      return true;
    }
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/project",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data;
          this.data_street = response.data_street;
          this.data_lot = response.data_lot;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Proyecto")
        : (this.modal.title = "Editar Proyecto");
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.project,
      })
        .then((response) => {
          if (this.modal.action == "store") {
            this.data.unshift(response[0]);
            this.mtd_getdata();
          } else {
            this.data.forEach((element) => {
              if (element.Id == response[0].Id) {
                element.Nombre = response[0].Nombre;
                element.Mapa = response[0].Mapa;
                element.Descripcion = response[0].Descripcion;
              }
            });
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            name: "",
            url_maps: "",
            description: "",
          };
          /** alert succes falta */
          bus.$emit("alert", {
            color: "success",
            message: "Proyecto agregado correctamente",
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.project = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.id != response) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };
          bus.$emit("alert", {
            color: "success",
            message: "Proyecto borrado",
          });
          this.mtd_getdata();
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    /** streets */
    show_street: function (id) {
      this.data.forEach((element) => {
        if (element.id == id) {          
          this.streets.id = id;
          this.streets.project_id = id;
          this.form_add_stret.project_id = id;
          this.streets.project_name = element.name;
          this.streets.data = [];
          element.strets.forEach((mz) => {
            let temp = {
              id: mz.id,
              name: mz.street_blocks.name,
              lots: mz.lots,
              color: "primary",
            };
            this.streets.data.push(temp);
          });
          this.lots= {
            project_streetblock_id: "",
            street_name: "",
            data: [],
          };
        }
      });
    },
    mtd_open_modal_srteet: function (boo, action) {
      this.modal_add_street.modal_form = boo;
      this.modal_add_street.action = action;
      action == "store"
        ? (this.modal_add_street.title = "Agregar etapa/manzana al proyecto")
        : (this.modal_add_street.title = "Editar etapa/manzana del proyecto");
    },
    modal_action_add_street: function () {
      this.post({
        url: this.$store.getters.get__url + "/projectstreetblock/store",
        token: this.$store.getters.get__token,
        params: this.form_add_stret,
      })
        .then((response) => {
          if (response.state == 0) {
            let temp = {
              id: response.data.id,
              name: response.data.street_blocks.name,
              lots: [],
              color: "primary",
            };
            this.streets.data.push(temp);
            this.modal_add_street = {
              action: "",
              title: "",
              modal_form: false,
            };
            this.mtd_getdata();
            bus.$emit("alert", {
              color: "success",
              message: "etapa/manzana agregada",
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: "Esta etapa/manzana ya está agregada al proyecto",
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_delete_street: function (item) {
      this.modal_delete_street.boo = true;
      this.modal_delete_street.item = item;
      this.modal_delete_street.item.Nombre = item.name;
    },
    close_delete_street: function () {
      this.modal_delete_street.boo = false;
    },
    mtd_commit_street: function () {
      this.post({
        url: this.$store.getters.get__url + "/projectstreetblock/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete_street.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          this.streets.data.forEach((element) => {
            if (element.id != response) {
              temp.push(element);
            }
          });
          this.streets.data = temp;
          this.modal_delete_street = {
            boo: false,
            item: [],
          };
          this.mtd_getdata();
          bus.$emit("alert", {
            color: "success",
            message: "Etapa/Manzana borrada",
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    /*** lots */
    show_lots: function (id) {
      this.streets.data.forEach((element) => {
        if (element.id == id) {
          console.log(element);
          this.lots.street_name = element.name;
          this.lots.project_streetblock_id = id;
          this.form_add_lot.project_streetblock_id = id;
          this.lots.data = [];
          element.lots.forEach((mz) => {
            let temp = {
              id: mz.id,
              name: mz.lot.name,
              color: "secondary",
              state: mz.state
            };
            this.lots.data.push(temp);
          })  
        }
      });
    },
    mtd_open_modal_lot: function (boo, action) {
      if (action == 'store') {
          this.form_add_lot.lot_id = "",
          this.form_add_lot.state =  1,
          this.form_add_lot.footage =  "";
          this.form_add_lot.amount =  "";
          this.form_add_lot.percentage_24 =  "";
          this.form_add_lot.percentage_36 =  "";
      }
      this.modal_add_lot.modal_form = boo;
      this.modal_add_lot.action = action;
      action == "store"
        ? (this.modal_add_lot.title = "Agregar Lote al proyecto")
        : (this.modal_add_lot.title = "Editar Lote del proyecto");
    },
    modal_action_add_lot: function () {
      // console.log(this.$store.getters.get__url + "/projectstreetblocklot/"+this.modal_add_lot.action);
      this.post({
        url: this.$store.getters.get__url + "/projectstreetblocklot/"+this.modal_add_lot.action,
        token: this.$store.getters.get__token,
        params: this.form_add_lot,
      })
        .then((response) => {
          if (this.modal_add_lot.action == "store") {
            if (response.state == 0) {
              let temp = {
                id: response.data.id,
                name: response.data.lot.name,
                color: "primary",
                state: response.data.lot.state
              };
              this.lots.data.push(temp);
              this.modal_add_lot = {
                action: "",
                title: "",
                modal_form: false,
              };
              bus.$emit("alert", {
                color: "success",
                message: "Lote agregado",
              });
              this.form_add_lot.lot_id = "";
              this.form_add_lot.state = 1;
              this.form_add_lot.footage = "";
              this.form_add_lot.amount = "";
              this.form_add_lot.percentage_24 = "";
              this.form_add_lot.percentage_36 = "";
              this.mtd_getdata();
            } else{              
              bus.$emit("alert", {
                color: "danger",
                message: "Este lote ya está agregada a la etapa/manzana",
              });
            }
          }else{           
            this.modal_add_lot = {
              action: "",
              title: "",
              modal_form: false,
            };
            bus.$emit("alert", {
              color: "success",
              message: "Editado correctamente",
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_delete_lot: function (item) {
      this.modal_delete_lot.boo = true;
      this.modal_delete_lot.item = item;
      this.modal_delete_lot.item.Nombre = item.name;
    },
    close_delete_lot: function () {
      this.modal_delete_lot.boo = false;
    },
    mtd_commit_lot: function () {
      this.post({
        url: this.$store.getters.get__url + "/projectstreetblocklot/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete_lot.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          this.lots.data.forEach((element) => {
            if (element.id != response) {
              temp.push(element);
            }
          });
          this.lots.data = temp;
          this.modal_delete_lot = {
            boo: false,
            item: [],
          };
          this.mtd_getdata();
          bus.$emit("alert", {
            color: "success",
            message: "Lote borrado",
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_edit_state: function(item){
      this.modal_edit_state_lot.modal_form = true;
      this.modal_edit_state_lot.action = 'edit';
      this.modal_edit_state_lot.title = "Editar estado del lote: "+item.name;
      this.form_edit_state.id=item.id;
      this.mtd_getdata();
    },
    modal_action_edit_state_lot: function(){
      this.post({
        url: this.$store.getters.get__url + "/projectstreetblocklot/editstate",
        token: this.$store.getters.get__token,
        params: this.form_edit_state,
      })
        .then((response) => {
          this.lots.data.forEach(element => {
            if (element.id == response.id) {
              element.state = response.state;
            }
          });

          this.modal_edit_state_lot = {
            action: "",
            title: "",
            modal_form: false,
          };
          bus.$emit("alert", {
            color: "success",
            message: "Lote editado",
          });
          this.form_edit_state.state =null;
          this.form_edit_state.id = null;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_edit_lot:function(item){
      this.get({
        url:
          this.$store.getters.get__url + "/projectstreetblocklot/" + item.id + "/show",
          token: this.$store.getters.get__token,
      })
        .then((response) => {                  
            this.form_add_lot = response;
            this.mtd_open_modal_lot(true,'update');
          
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    /** maps */
    mtd_view_map: function (url) {
      this.maps.boo=true;
      this.maps.url = url;
    },
    modal_action_close_maps: function(){
      this.maps.boo = false;
      this.maps.url = "";
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}

.scroll-card {
  position: relative;
  overflow-y: scroll;
  height: 230px;
}

.scrollbar-primary::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #2819ae;
}

.scrollbar-primary {
  scrollbar-color: #2819ae #f5f5f5;
}

.thin::-webkit-scrollbar {
  width: 6px;
}
.bordered-primary::-webkit-scrollbar-track {
  // -webkit-box-shadow: none;
  border: 1px solid #2819ae;
}

// .bordered-primary::-webkit-scrollbar-thumb {
//   // -webkit-box-shadow: none;
// }
</style>